@import '../../styles/variables.scss';


.Glyph {
  display: flex;
  position: relative;
  // font-size: 2rem;
  
  width: 100%;
  height: 100%;
  
  justify-content: center;
  align-items: center;
  color: $blue-20;
  background-color: $blue-90; //$gray-100;
  transition: 300ms;
  cursor: pointer;
  &:hover {
    color: $gray-20;
    background-color: $gray-90-hover;
    box-shadow: $elev-500;
    z-index: 100;
    .glyph-action,
    .glyph-code {
      display: block;
    }
  }
  &:active {
    background-color: $gray-30;
  }
  &:focus {
    outline: 2px solid $primary-color;
    outline-offset: 1px;
    z-index: 1;
  }
  a {
    display: flex;
    color: inherit;
    text-decoration: none;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
}

// .glyph-symbol {
//   font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif, Helvetica, sans-serif;
// }

.glyph-aria-id {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0;
}

.glyph-number,
.glyph-code,
.glyph-action{
  position: absolute;
  display: none;
}

.glyph-number,
.glyph-code {
  top: 0;
  color: $gray-60;
  font-size: 0.75rem;
  padding: 0.5rem;
}
.glyph-number {
  left: 0;
}
.glyph-code {
  right: 0;
}
.glyph-action {
  bottom: 0;
  right: 0;
  width: 2.5rem;
  height: 2.5rem;
  padding: 1rem;
  .spbx--icon {
    display: flex;
    width: 1rem;
    height: 1rem;
  }
}