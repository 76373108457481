$font-stack: 'Space Grotesk', sans-serif;
$font-settings-01: "zero" on,"ss01" off,"ss02" on,"ss03" on,"ss04" on,"ss05" on,"liga" off;

$black: #000000;
$white: #ffffff;

$gray-10: #f4f4f4;
$gray-20: #e0e0e0;
$gray-30: #c6c6c6;
$gray-40: #a8a8a8;
$gray-50: #8d8d8d;
$gray-60: #6f6f6f;
$gray-70: #525252;
$gray-80: #393939;
$gray-90: #262626;
$gray-100: #161616;

$gray-10-hover: #E8E8E8;
$gray-20-hover: #D1D1D1;
$gray-30-hover: #B5B5B5;
$gray-40-hover: #999999;
$gray-50-hover: #7A7A7A;
$gray-60-hover: #5E5E5E;
$gray-70-hover: #636363;
$gray-80-hover: #474747;
$gray-90-hover: #333333;
$gray-100-hover: #292929;

$yellow-20: #f1c21b;
$yellow-30: #ffff00;
$yellow-40: #d2a106;
$yellow-50: #b28600;

$orange-30: #ff832b;
$orange-40: #ffa500;
$orange-60: #ba4e00;
$orange-70: #8a3800;

$red-10: #fff1f1;
$red-20: #ffd7d9;
$red-30: #ffb3b8;
$red-40: #ff8389;
$red-50: #fa4d56;
$red-60: #da1e28;
$red-70: #a2191f;
$red-80: #750e13;
$red-90: #520408;
$red-100: #2d0709;

$magenta-10: #fff0f7;
$magenta-20: #ffd6e8;
$magenta-30: #ffafd2;
$magenta-40: #ff7eb6;
$magenta-50: #ee5396;
$magenta-60: #d02670;
$magenta-70: #9f1853;
$magenta-80: #740937;
$magenta-90: #510224;
$magenta-100: #2a0a18;

$purple-10: #f6f2ff;
$purple-20: #e8daff;
$purple-30: #d4bbff;
$purple-40: #be95ff;
$purple-50: #a56eff;
$purple-60: #8a3ffc;
$purple-70: #6929c4;
$purple-80: #491d8b;
$purple-90: #31135e;
$purple-100: #1c0f30;

$blue-10: #edf5ff;
$blue-20: #d0e2ff;
$blue-30: #a6c8ff;
$blue-40: #78a9ff;
$blue-50: #4589ff;
$blue-60: #0f62fe;
$blue-70: #0043ce;
$blue-80: #002d9c;
$blue-90: #001d6c;
$blue-100: #001141;

$cyan-10: #e5f6ff;
$cyan-20: #bae6ff;
$cyan-30: #82cfff;
$cyan-40: #33b1ff;
$cyan-50: #1192e8;
$cyan-60: #0072c3;
$cyan-70: #00539a;
$cyan-80: #003a6d;
$cyan-90: #012749;
$cyan-100: #061727;

$teal-10: #d9fbfb;
$teal-20: #9ef0f0;
$teal-30: #3ddbd9;
$teal-40: #08bdba;
$teal-50: #009d9a;
$teal-60: #007d79;
$teal-70: #005d5d;
$teal-80: #004144;
$teal-90: #022b30;
$teal-100: #081a1c;

$green-10: #defbe6;
$green-20: #a7f0ba;
$green-30: #6fdc8c;
$green-40: #42be65;
$green-50: #24a148;
$green-60: #198038;
$green-70: #0e6027;
$green-80: #044317;
$green-90: #022d0d;
$green-100: #071908;

$red-color: #da1e28;
$green-color: #24a148;
$blue-color: #0029ff;

$yellow-color: #fdd13a;
$orange-color: #ff832b;

$primary-color: $blue-color;
$danger-color: $red-color;
$warning-color: $yellow-color;

$secondary-color: $gray-100;
$tetrinary-color: $gray-30;


$background: $gray-10;

$gray-elevation-0: rgba(141, 141, 141, 0.12);

$gray-elevation-40: rgba($gray-50, 0.4);
$gray-elevation-32: rgba($gray-50, 0.32);
$gray-elevation-24: rgba($gray-50, 0.24);
$gray-elevation-16: rgba($gray-50, 0.16);
$gray-elevation-12: rgba($gray-50, 0.12);
$gray-elevation-8: rgba($gray-50, 0.08);
$gray-elevation-4: rgba($gray-50, 0.04);


$elev-100: 0 2px 4px rgba(0, 0, 0, 0.25), 0 4px 8px rgba(0, 0, 0, 0.25);
$elev-300: 0 4px 8px rgba(0, 0, 0, 0.25), 0 8px 16px rgba(0, 0, 0, 0.25);
$elev-500: 0 8px 16px rgba(0, 0, 0, 0.25), 0 16px 32px rgba(0, 0, 0, 0.25);
$elev-700: 0 16px 32px rgba(0, 0, 0, 0.25), 0 32px 64px rgba(0, 0, 0, 0.25);



// Deprecated
// $gray-100: #151515;
// $gray-90: #1a1a1a;
// $gray-80: #333333;
// $gray-70: #4d4d4d;
// $gray-60: #666666;
// $gray-50: #808080;
// $gray-40: #9a9a9a;
// $gray-30: #b3b3b3;
// $gray-20: #cdcdcd;
// $gray-10: #e6e6e6;