.productive-heading-07 {
  font-size: 3.375rem;
  line-height: 4rem;
  font-weight: 300;
  letter-spacing: 0;
}

// @import '../media-queries.scss';

.responsive-heading-06 {
  font-weight: 300;
  font-size: 2rem;
  

  @media (min-width: 20rem) and (max-width: 65.9375rem) {
    font-size: 2.25rem;
    font-size: calc(1.55rem + 1.48vw);

  }
  @media (min-width: 66rem) and (max-width: 81.9375rem) {
    font-size: 3rem;
    font-size: calc(2.05rem + 1.48vw);

  }
  @media (min-width: 82rem) {
    font-size: 3.5rem;
  }
}


.responsive-heading-05 {
  font-weight: 300;
  font-size: 1.5rem;
  

  @media (min-width: 20rem) and (max-width: 65.9375rem) {
    font-size: 1.75rem;
    font-size: calc(1.35rem + 1.48vw);
  }
  @media (min-width: 66rem) and (max-width: 81.9375rem) {
    font-size: 2.5rem;
    font-size: calc(1.75rem + 1.48vw);
  }
  @media (min-width: 82rem) {
    font-size: 3rem;
  }
}

.responsive-heading-04 {
  font-weight: 400;
  font-size: 1.25rem;
  

  @media (min-width: 20rem) and (max-width: 65.9375rem) {
    font-size: 1.5rem;
    font-size: calc(1.05rem + 1.48vw);
  }
  @media (min-width: 66rem) and (max-width: 81.9375rem) {
    font-size: 1.75rem;
    font-size: calc(0.95rem + 1.48vw);
  }
  @media (min-width: 82rem) {
    font-size: 2rem;
  }
}


.responsive-paragraph-03 {
  font-weight: 300;
  font-size: 1.25rem;
  

  @media (min-width: 20rem) and (max-width: 65.9375rem) {
    font-size: 1.25rem;
    font-size: calc(1.05rem + 0.88vw);
  }
  @media (min-width: 66rem) and (max-width: 81.9375rem) {
    font-size: 1.5rem;
    font-size: calc(1.25rem + 0.48vw);
  }
  @media (min-width: 82rem) {
    font-size: 1.75rem;
  }
}

.responsive-paragraph-02 {
  font-weight: 400;
  font-size: 1rem;
  

  @media (min-width: 20rem) and (max-width: 65.9375rem) {
    font-size: 1rem;
    font-size: calc(0.85rem + 0.88vw);
  }
  @media (min-width: 66rem) and (max-width: 81.9375rem) {
    font-size: 1.25rem;
    font-size: calc(1.05rem + 0.48vw);
  }
  @media (min-width: 82rem) {
    font-size: 1.5rem;
  }
}


// @media (min-width: 20rem) and (max-width: 41.9375rem) {
//   font-size: 1rem;
// }
// @media (min-width: 42rem) and (max-width: 65.9375rem) {
//   font-size: 1rem;
// }
// @media (min-width: 66rem) and (max-width: 81.9375rem) {
//   font-size: 1rem;
// }
// @media (min-width: 82rem) and (max-width: 98.9375rem) {
//   font-size: 1rem;
// }
// @media (min-width: 99rem) {
//   font-size: 1rem;
// }

h1 {
  font-size: 2.625rem;
  line-height: 2.125rem;
  font-weight: 300;
  letter-spacing: 0;
}

h2 {
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 400;
  letter-spacing: 0;
}

h3 {
  font-size: 1.75rem;
  line-height: 2.25rem;
  font-weight: 400;
  letter-spacing: 0;
  // color: red;
}

h4 {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0;
}

h5 {
  font-size: 1rem;
  line-height: 1.375rem;
  font-weight: 600;
  letter-spacing: 0;
}

h6 {
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: 600;
  letter-spacing: 0;
}

p, span {
  font-size: 0.875rem;
  line-height: 1.3;
  font-weight: 400;
  letter-spacing: 0.1px;
}

strong {
  font-weight: 900;
}

.monoblock {
  text-transform: uppercase;
  line-height: 1.0;
}