@import '../../styles/variables.scss';

.Notification {
  position: sticky;
  display: flex;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0;
  color: $gray-100;
  z-index: 1000;
}
.Notification-container {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  
}
.Notification-content {
  display: flex;
  align-items: center;
  padding: 1rem;
  background: $white;
  margin: 1rem auto;
  box-shadow: $elev-700;
  .glyph {
    margin: 0 0.25rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
  }
}

