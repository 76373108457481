@import '../../styles/variables.scss';

.Footer {
  min-height: 25vh;
  color: $gray-60;
  font-feature-settings: $font-settings-01;
  background-color: $black;

  .Footer-container {
    padding: 2rem 0;

    p {
      font-size: 1rem;
      margin: 0 0 0.5rem;
    }
  }
}