@import 'variables.scss';


@font-face {
  font-family: 'Space Grotesk';
  font-weight: 300;
  font-display: swap;
  src: url('../../public/assets/fontface/SpaceGrotesk-Var.woff2') format('woff2');
}

@font-face {
  font-family: 'Space Grotesk';
  font-weight: 400;
  font-display: swap;
  src: url('../../public/assets/fontface/SpaceGrotesk-Var.woff2') format('woff2');
}