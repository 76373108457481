@import '../../styles/variables.scss';

$gutter: 1px;

.Gallery-module {
  min-height: 62.5vw;
}
.Glyphs-module {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -1rem;
  margin-bottom: 4rem;
}

.glyph-library {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 3rem;
}


.Card {
  padding: $gutter;
  &.aspect-ratio-flex {
    flex-grow: 1;
    min-width: calc(100% / 4);
  }
  &.aspect-ratio-1-1 {
    .Card-inside {
      padding-bottom: 100%;
    }

    max-width: calc(100% / 16);
    flex: 0 0 calc(100% / 16);

    @media (max-width: 133rem) {
      max-width: calc(100% / 12);
      flex: 0 0 calc(100% / 12);
    }
    @media (max-width: 99rem) {
      max-width: calc(100% / 8);
      flex: 0 0 calc(100% / 8);
    }
    @media (max-width: 82rem) {
      max-width: calc(100% / 8);
      flex: 0 0 calc(100% / 8);
    }
    @media (max-width: 66rem) {
      max-width: calc(100% / 8);
      flex: 0 0 calc(100% / 8);
    }
    @media (max-width: 42rem) {
      max-width: calc(100% / 4);
      flex: 0 0 calc(100% / 4);
    }
    @media (max-width: 33rem) {
      max-width: calc(100% / 4);
      flex: 0 0 calc(100% / 4);
    }

  }
}
.Card-inside {
  position: relative;
  width: 100%;
  height: 100%;
}

.Card-object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}