@import 'styles/variables.scss';
@import 'styles/typography.scss';
@import './styles/fibre.scss';

* {
  box-sizing: border-box;
}
html {
  font-size: 16px;
}
body {
  margin: 0;
  font-family: $font-stack;
  font-feature-settings: $font-settings-01;
  background-color: $blue-40;
  color: $blue-80;
}
ul {
  padding: 0;
}
li {
  list-style-type: none;
}



.App {
  min-width: 20rem;
}

.Head {
  display: flex;
  flex-direction: column;
  // color: $blue-20;
}

.lead-space {
  margin: 1rem 0;
  // color: $blue-20;
}

.logo {
  display: flex;
  padding: 1rem 0;
  position: relative;
  font-size: 3rem;
  height: 9rem;
  margin: 1px;
}

.Navigation {
  padding: 3rem 0;
  
  ul {
    display: flex;
    margin: 0 -1rem;
  }


}
.NavLink {
  padding: 1rem 1rem 2rem 1rem;
  color: $blue-80;
  background-color: $blue-40;
  transition: 150ms;
  &:hover {
    color: $blue-80;
    background-color: $blue-30;
  }

  .glyph {
    margin-left: 1.5rem;
  }
}

.Link {
  // padding: 0rem 1rem 2rem 1rem;
  color: $blue-80;
  transition: 150ms;
  &:hover {
    color: $blue-100;
  }

  .glyph {
    margin-left: 1.5rem;
  }
}

.About {
  margin-bottom: 4rem;
}

